import React from "react"
import Title from "../Title"
import styles from "../../css/contact.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Success = () => {
  return (
    <section className={styles.contact}>
      <Title title="contactez" subtitle="nous" />
      <div className={styles.center}>
        <article className={styles.info}>
          <p className={styles.desc}>
            Merci de nous avoir contacté. Nous allons prendre connaissance de
            votre message et nous ne manquerons pas de vous rappeler très vite.
          </p>
          <AniLink
            style={{ marginTop: "3rem" }}
            fade
            to="/"
            className="btn-primary"
          >
            Accueil
          </AniLink>
        </article>
      </div>
    </section>
  )
}

export default Success
